
.divstyle{
    width: 18rem;
    margin: 19px 30px;
    transition: all 0.7s ease;
}
.divstyle:hover{
    transition: all 0.7s ease;
    box-shadow: rgb(144 202 249 / 35%) 0px 54px 55px, rgb(144 202 249 / 22%) 0px -12px 30px, rgb(144 202 249 / 22%) 0px 4px 6px, rgb(144 202 249 / 27%) 0px 12px 13px, rgb(144 202 249 / 19%) 0px -3px 5px;
    cursor: pointer;
}
.divstyle .link{
    border: none;
    background: #08ad5d;
}
.divstyle .link:hover{
    background: #06d471;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}