@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;800&display=swap");

.FocusSectors{
  
  background-color:  #f9f0d4;
}
.heading{
  padding-top: 60px;
}
.FocusSectors .Container{
    text-align: center;
    font-family: Arial;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
margin: auto;
    font-family: "Poppins", sans-serif;
  }
