@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;800&display=swap");
:root {
    --red: hsl(0, 78%, 62%);
    --cyan: hsl(180, 62%, 55%);
    --orange: hsl(34, 97%, 64%);
    --blue: hsl(212, 86%, 64%);
    --varyDarkBlue: hsl(234, 12%, 34%);
    --grayishBlue: hsl(229, 6%, 66%);
    --veryLightGray: hsl(0, 0%, 98%);
    --weight1: 200;
    --weight2: 400;
    --weight3: 600;
}

.SmallCard {
    border-radius: 5px;
    box-shadow: 0px 30px 40px -20px var(--grayishBlue);
    padding: 30px;
    margin: 20px;  
    border-top: 3px solid var(--red);
    width: 500px;
    border-radius: 15px;
}
.SmallCard p {
    color: var(--grayishBlue);
}
.SmallCard img{
    height: 100px;
    width: 100px;
  }
.SmallCard .h2 {
    color: var(--varyDarkBlue);
    font-weight: var(--weight3);
}


@media (max-width: 950px) and (min-width: 450px) {
    .SmallCard {
        text-align: center;
    }
}