:root{
  --background-dark: #2d3548;
  --text-light: rgba(255,255,255,0.6);
  --text-lighter: rgba(255,255,255,0.9);
  --spacing-s: 8px;
  --spacing-m: 16px;
  --spacing-l: 24px;
  --spacing-xl: 32px;
  --spacing-xxl: 64px;
  --width-container: 1200px;
}

  .person-card{
    list-style: none;
    position: relative;
    border: none;
  }
  
  .person-card:before{
    content: '';
    display: block;
    padding-bottom: 150%;
    width: 100%;
  }
  
  .card__background{
    background-size: cover;
    background-position: center;
    border-radius: var(--spacing-l);
    bottom: 0;
    filter: brightness(.85) saturate(1.2) contrast(0.85);
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform-origin: center;
    transform: scale(1) translateZ(0);
    transition: 
      filter 200ms linear,
      transform 200ms linear;
  }
  
  .person-card:hover .card__background{
    transform: scale(1.05) translateZ(0);
  }
  
  .card-grid:hover > .person-card:not(:hover) .card__background{
    filter: brightness(0.5) saturate(0) contrast(1.2) blur(20px);
  }
  
  .card__content{
    left: 15px;
    position: absolute;
    top: 340px;
  }
  
  .card__category{
    color: var(--text-light);
    font-size: 0.9rem;
    margin-bottom: var(--spacing-s);
    text-transform: uppercase;
  }
  
  .card__heading{
    color: rgb(255, 255, 255);
   
    text-shadow: 2px 2px 20px rgba(0,0,0,0.2);
   
  }