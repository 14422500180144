@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:300');
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:600');

.FormCard input:active, input:focus {
  outline: none;
}
.FormCard input{
    border: 0.1vh solid #637282;
    border-radius: 0.6vh;
    box-sizing: border-box;
    border-radius: 20px;
    font-weight: 300;
    font-size: 18px;
    color: #637282;
    font-family: inherit;
    padding:13px 25px;
    background: hsla(0,0%,100%,.4);
    margin-bottom: 20px;  
    width:350px;
    max-width: 100%;   
}

.FormCard ul{
    list-style: none;
    padding: 0
}

.FormCard ul li{
    display: inline-block;
    padding-right: 10px;     
}
.FormCard .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px){
    .FormCard .container {
    max-width: 540px;
}
}

@media (min-width: 768px){
    .FormCard .container {
    max-width: 720px;
}
}

@media (min-width: 992px){
    .FormCard .container {
    max-width: 960px;
}
}

@media (min-width: 1200px){
    .FormCard .container {
    max-width: 1140px;
}
}




.FormCard .wrapper{
    margin: 125px 0;

}

.FormCard .form{
    display: flex;
    text-align: center;
    justify-content: center; 
}



.FormCard .right-col{
    width: 60%;
   margin: auto;
   padding: 70px 0px;  
 
  border: 0.1px solid #e2e2e2;
  border-left: none;
}

.FormCard .right-col h2{
    margin-bottom: 70px;
    font-size: 30px;
    color:#4e4e4e;
    font-weight: 600;    
}

.FormCard .right-col button{
    background-color: #156eff;  
    border: none; margin-left: 12px;
    transition: all 0.7s ease;
    border-radius: 18px;
    font-weight: 600;
    font-size: 20px;
    color: #FFFFFF;
    padding: 10px 20px;
    margin-top: 20px;
    border:none;
    cursor: pointer;
}
.FormCard .right-col button:hover{
    background-color: #3f88ff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: all 0.7s ease;
}
.FormCard .left{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    border: none;
   }
@media screen and (max-width: 992px) {
    .FormCard .form{
        display: block;
    }
    .FormCard .left-col img.img1{
        display: none
    }

    .FormCard .right-col, .left-col{
        width:100%;
        padding: 40px 0;
    }

}