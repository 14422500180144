@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;800&display=swap");
:root {
    --red: hsl(0, 78%, 62%);
    --cyan: hsl(180, 62%, 55%);
    --orange: hsl(34, 97%, 64%);
    --blue: hsl(212, 86%, 64%);
    --varyDarkBlue: hsl(234, 12%, 34%);
    --grayishBlue: hsl(229, 6%, 66%);
    --veryLightGray: hsl(0, 0%, 98%);
    --weight1: 200;
    --weight2: 400;
    --weight3: 600;
}

.apply-card {
    border-radius: 5px;
    box-shadow: 0px 30px 40px -20px var(--grayishBlue);
    padding: 30px;
    margin: 20px auto;  
    border-top: 3px solid var(--red);
    width: 75%;
    border-radius: 15px;
    text-align: left;
    display: flex;
   justify-content: space-between;
}
.apply-card p {
    color: var(--grayishBlue);
}
.apply-card .content{
    display: flex;
}
.apply-card .text-content{
    margin: 0px 35px;
}
.apply-card img{
    height: 75px;
    width: 100px;
  }
.apply-card .h2 {
    color: var(--varyDarkBlue);
    font-weight: var(--weight3);
}

.apply-card .Link {
    padding:10px;
    background: #08ad5d;
    border-radius: 5px;
    text-decoration: none;
    color: white;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    transition: 0.5s;
    margin-top: 30px;
    height: 45px;
    display: flex;
    
  }
.apply-card .Link:hover {
    background: #06d471;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  .apply-card i{
    margin-left: 10px;
    margin-top: 5px;
  }
@media (max-width: 950px) {
    .apply-card {
        text-align: center;
        flex-wrap: wrap;
    }
    .apply-card img{
        margin: auto;
    }
    .apply-card .Link{
        margin: auto;
    }
}