.OurStorymain{
    display: flex;
    justify-content: center;
    margin: 40px 0px;
    
    
}
.OurStory{
    display: flex;
    flex-wrap: wrap;
    /* margin-right: -15px;
    margin-left: -15px;  */
    padding-top: 50px;  
    background-color: #5F87CC;
    width:1100px;
    border-radius: 20px;
   margin: auto;
    color: white;
    
}
.OurStory #header_title{
    padding-left: 47px;
}
.wrap_items{
    display: flex;
    padding: 0px 50px 110px 50px;
    justify-content: center;


}
.wrap_items .content1{

   font-size: 20px;
   
   
}
.wrap_items .content2{
   margin-left: 10px;
   font-size: 16px;
}
.bottomlogo{
    width: 200px;
    height: 75px;
 
  }
.bottomlogo img{
    width: 150px;
    height: 50px;
    /* border: 1px solide red; */
  }
@media (max-width:950px) {
  .wrap_items{
      flex-direction: column;
  }
  .wrap_items .content2{
      margin-top: 20px;
  }
}