.PingCards .container .card {
  position: relative;
  width: 320px;
  height: 440px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
    inset -5px -5px 15px rgba(255, 255, 255, 0.1),
    5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  margin:30px 0px;
  transition: 0.5s;
}

.PingCards .container .card:nth-child(1n) .box .content .Link {
  background: #08ad5d;
}

.PingCards .container .card:nth-child(2n) .box .content a {
  background: #e99b1e;
}

.PingCards .container .card:nth-child(3n) .box .content a {
  background: #23c186;
}

.PingCards .container .card .box {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background:#e0d5b3;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.5s;
}

.PingCards .container .card .box:hover {
  transform: translateY(-50px);
  cursor: pointer;
}

.PingCards .container .card .box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background:   rgb(255 255 255 / 28%)
}

.PingCards .container .card .box .content {
  padding: 20px;
  text-align: center;
}

.PingCards .container .card .box .content h2 {
  position: absolute;
  top: -10px;
  right: 30px;
  font-size: 8rem;
  color: rgba(255, 255, 255, 0.1);
}

.PingCards .container .card .box .content h3 {
  font-size: 1.8rem;
  color: #393535;
  z-index: 1;
  transition: 0.5s;
  margin-bottom: 15px;
}

.PingCards .container .card .box .content p {
  font-size: 1rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  z-index: 1;
  transition: 0.5s;
}

.PingCards .container .card .box .content .Link {
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  background: black;
  border-radius: 5px;
  text-decoration: none;
  color: white;
  margin-top: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}
.PingCards .container .card .box .content .Link:hover {
  background: #06d471;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.PingCards .img{
  display: flex;
  justify-content: center;
  
}
.PingCards img{
  height: 100px;
  width: 100px;
  color: #e71d36;
}