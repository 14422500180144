.topbar{
    width: 100%;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border: 0.1px solid black;
}
.email {
    font-size: 14px;
    display: flex;
    float: left;
    padding: 1px;
    align-items: center;
    
}
.phone {
    font-size: 14px;
    display: flex;
    float: left;
    padding: 1px;
    align-items: center;
    
}
.email i{
    display: flex;
    margin: 0px 1px;
    height: 14px;
    width: 15px;
    
}
.phone i{
    display: flex;
    margin: 0px 1px;
    height: 14px;
    width: 15px;
    
}
.socialmedia{
    float: right;

}
.socialmedia a{
    text-decoration: none;
}

.socialmedia i{
    color: black;
    padding: 8px;
    transition: all 0.7s ease;
}
#twiter:hover {
    color: #00acee;
    transition: all 0.7s ease;
    transform: scale(1.3);
}
#facebook:hover{
    color: #4267B2;
    transition: all 0.7s ease;
    transform: scale(1.3);
}
#insta:hover{
    color: #E1306C;
    transition: all 0.7s ease;
    transform: scale(1.3);
}
#youtube:hover{

    color:  #FF0000;
    transition: all 0.7s ease;
    transform: scale(1.3);
}
#linkdin:hover{
    color:  #0077b5;
    transition: all 0.7s ease;
    transform: scale(1.3);
}
@media (max-width:850px) {
    .socialmedia{
        display: none;
    }
}