.outercard{
    display: flex;
    margin: 15px;
    justify-content: center;
    /* border: 2px solid black; */
    text-align: center;
    font-family: Arial;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin-bottom: 20px;
margin: auto;
    font-family: "Poppins", sans-serif;
}
.MoreMaanof{
    padding: 30px 0px;
    background-color: #f9f0d4;
}