  
  .a-box {
    text-align: center;
    position: relative;
  width: 320px;

  margin: 25px 20px;
  
  }

  .img-container {
      height: 230px; 
      width: 200px;
      overflow: hidden;
      border-radius: 0px 0px 20px 20px;
      display: inline-block;
  }
  
  .img-container img {
      transform: skew(0deg, -13deg);
      height: 250px;
      margin: -35px 0px 0px -94px;
  }
  
  .inner-skew {
      display: inline-block;
      border-radius: 20px;
      overflow: hidden;
      padding: 0px;
      transform: skew(0deg, 13deg);
      font-size: 0px;
      margin: 30px 0px 0px 0px;
      background: #c8c2c2;
      height: 250px;
      width: 200px;
  }
  
  .text-container {
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    padding: 120px 20px 20px 20px;
    border-radius: 20px;
    background: #fff;
    margin: -120px 0px 0px 0px;
    line-height: 19px;
    font-size: 14px;
    transition: all 0.7s ease;
  }
  .text-container:hover{
    box-shadow: rgb(129 199 132 / 25%) 0px 54px 55px, rgb(129 199 132 / 22%) 0px -12px 30px, rgb(129 199 132 / 22%) 0px 4px 6px, rgb(129 199 132 / 27%) 0px 12px 13px, rgb(129 199 132 / 19%) 0px -3px 5px;
    transition: all 0.7s ease;
    cursor: pointer;
  }
  
  .text-container h3 {
    margin: 20px 0px 10px 0px;
    color: #04bcff;
    font-size: 18px;
  }
  .text-container .Link{
    position: relative;
    display: inline-block;
    padding: 8px 20px;
    background: black;
    border-radius: 5px;
    text-decoration: none;
    color: white;
    margin-top: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    transition: 0.5s;
    background-color: #156eff;  
  }
  .text-container .Link:hover{
    background-color: #3f88ff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: all 0.7s ease;
  }
  @media (max-width:450px) {
    .a-box{
      margin: 45px 20px;
    }
  }