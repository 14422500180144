@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:300');
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:600');

.ContactCard input:active, input:focus {
  outline: none;
}
.ContactCard input{
    border: 0.1vh solid #637282;
    border-radius: 0.6vh;
    box-sizing: border-box;
    border-radius: 20px;
    font-weight: 300;
    font-size: 18px;
    color: #637282;
    font-family: inherit;
    padding:13px 25px;
    background: hsla(0,0%,100%,.4);
    margin-bottom: 20px;  
    width:350px;
    max-width: 100%;    
}

.ContactCard ul{
    list-style: none;
    padding: 0
}

.ContactCard ul li{
    display: inline-block;
    padding-right: 10px;     
}
.ContactCard .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px){
    .ContactCard .container {
    max-width: 540px;
}
}

@media (min-width: 768px){
    .ContactCard .container {
    max-width: 720px;
}
}

@media (min-width: 992px){
    .ContactCard .container {
    max-width: 960px;
}
}

@media (min-width: 1200px){
    .ContactCard .container {
    max-width: 1140px;
}
}




.ContactCard .wrapper{
    margin: 125px 0;

}

.ContactCard .form{
    display: flex;
    text-align: center;
    justify-content: center; 
}

.ContactCard .left-col{
    width: 40%;
    padding: 30px 30px 0 30px;
    background: #f9f0d4;

}



.ContactCard .left-col h2{
    color:#4e4e4e;
    font-size:32px;
    line-height: 37px;
}

.ContactCard .left-col img.img1{
    margin-bottom: 10px;
}

.ContactCard .left-col .bull{
    margin: 100px 0 0;
}
.ContactCard .left-col .bull p{
    font-size: 20px;
    color: #4e4e4e;
}

.ContactCard .left-col .bull img.img2{
    margin:25px 0;
}

.ContactCard .left-col .soc{
    margin: 100px 0 0;
}
.ContactCard .left-col .soc ul{
    padding: 0px 20px;  
}
.ContactCard .left-col .soc a i{
    transition: 0.5s;   
}
.ContactCard .left-col .soc a {
    text-decoration: none;  
    padding: 10px;
}
.ContactCard .left-col .soc a i:hover{
    transition: 0.5s;
    transform: scale(1.3)
}

.ContactCard .left-col .soc p{
    color: #4e4e4e;
    font-size: 18px;
    font-weight: 300;
}

.ContactCard .left-col .soc ul{
    padding:20px 0 50px;
}


.ContactCard .right-col{
    width: 60%;
   margin: auto;
   padding: 70px 0px;  
   box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  
}

.ContactCard .right-col h2{
    margin-bottom: 70px;
    font-size: 30px;
    color: #585858;
    font-weight: 600;    
}

.ContactCard .right-col button{
    background-color: #156eff;  
    border: none; margin-left: 12px;
    transition: all 0.7s ease;
    border-radius: 18px;
    font-weight: 600;
    font-size: 20px;
    color: #FFFFFF;
    padding: 10px 20px;
    margin-top: 20px;
    border:none;
    cursor: pointer;
}
.ContactCard .right-col button:hover{
    background-color: #3f88ff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: all 0.7s ease;
}
@media screen and (max-width: 992px) {
    .ContactCard .form{
        display: block;
    }
    .ContactCard .left-col img.img1{
        display: none
    }

    .ContactCard .right-col{
        width:100%;
    }
    .ContactCard .left-col{
        width:100%;
    }

}