@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;500&display=swap');
.intro{
     background-color: #f9f0d4;
     
     margin-bottom: 20px;
 
}
.intro .Container{
    display: flex;
    flex-direction: column;
    margin-left: 10%;
    margin-right: 10%;
   font-family: 'Josefin Sans', sans-serif;
padding: 60px 0px;
}
.intro section{
    display: flex;
}
.intro-image1 {
    padding-right: 54px;
    margin-right: 54px;
    border-right: 2px solid red;

}
.intro-image1 img{
    height: 320px;
    width: 300px;
    border: 1px solid grey;
    border-radius: 14px;
}
.intro-content{
    text-align: left;
    margin: auto;
    font-size: 24px;
    
}
.intro-content p{
    color: grey;
}
.intro-content h2{
    font-weight: 1200;
}
@media (max-width:950px) {
    .intro-image1 img{
        height: 250px;
        width: 230px;
        border: 1px solid grey;
        border-radius: 14px;
    }
    .intro section{
        flex-direction: column;
    }
    .intro-image1{
        margin: 0px;
        padding: 0px;
      border: none;
      margin-bottom: 40px
    }
    .intro-content{
      text-align: center;
        
    }
}