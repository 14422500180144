
  .footer a {
    color: #fff;
    text-decoration: none;
  }
  .footer .link {
    color: #fff;
    text-decoration: none;
  }
  .footer .page-footer {
    font-family: 'Roboto', sans-serif;
    
  }
  
  
  .footer {
    background-color: #222023;
    background-image: linear-gradient( to bottom, #5c5c5c, #110f0f  );
    color: #fff;
    font-size: 17px;
  }
  .footer-wave-style {
      background-color: transparent;
      display: block;
      height: 30px;
      position: relative;
      top: -1px;
      width: 100%;
      
  }
  .footer-wave-path {
      fill: #ffffff;
  }
  
  .footer-content {
      display: flex;
      justify-content: space-evenly;
      margin-left: auto;
      margin-right: auto;
      max-width: 1230px;
      padding: 40px 15px 100px;
      position: relative;
  }
  
  .footer-content-column {
      box-sizing: border-box;
      float: left;
      padding-left: 40px;
      padding-right: 40px;
      width: 100%;
      color: #fff;
  }
  
  .footer-content-column ul li a {
    color: rgb(130, 130, 130);
    text-decoration: none;
  }
  .footer-content-column ul li .link {
    color: rgb(130, 130, 130);
    text-decoration: none;
  }
  .footer-content-column ul li a:hover{
    color: #ed1b24;
  }
  .footer-content-column ul li .link:hover{
    color: #ed1b24;
  }
  .footer-logo-link {
      display: inline-block;
  }
  .footer-logo img{
    height: 72px;
    width: 230px;
  }
  .footer-menu {
      margin-top: 30px;
      margin-bottom: 20px;
  }
  
  .footer-menu-name {
      color: #fffff2;
      font-weight: 900;
      letter-spacing: .1em;
      line-height: 18px;
      border-bottom: 2px solid #a61f2d;
      padding-bottom: 14px;
      margin-top: 0;
      text-transform: uppercase;
  }
  .footer-menu-list {
      list-style: none;
      margin-bottom: 0;
      margin-top: 10px;
      padding-left: 0;
  }
  .footer-menu-list li {
      margin-top: 5px;
  }
  
  
  
  
  .footer-social-links {
      bottom: 0;
      height: 54px;
      position: absolute;
      right: 0;
      width: 236px;
  }
  
  .footer-social-amoeba-svg {
      height: 54px;
      left: 0;
      display: block;
      position: absolute;
      top: 0;
      width: 236px;
  }
  
  .footer-social-amoeba-path {
      fill: #000000;
  }
  
  .footer-social-link.linkedin {
      height: 26px;
      left: 3px;
      top: 11px;
      width: 26px;
  }
  
  .footer-social-link {
      display: block;
      padding: 10px;
      position: absolute;
  }
  
  .hidden-link-text {
      position: absolute;
      clip: rect(1px 1px 1px 1px);
      clip: rect(1px,1px,1px,1px);
      -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
      clip-path: inset(0px 0px 99.9% 99.9%);
      overflow: hidden;
      height: 1px;
      width: 1px;
      padding: 0;
      border: 0;
      top: 50%;
  }
  
  .footer-social-icon-svg {
      display: block;
      height: 26px;
  }
  
  .footer-social-icon-path {
      fill: #fffff2;
      transition: fill .2s;
  }
  
  .footer-social-link.twitter {
      height: 28px;
      left: 62px;
      top: 3px;
      width: 28px;
       
  }
  
  .footer-social-link.youtube {
      height: 24px;
      left: 123px;
      top: 12px;
      width: 24px;
 
  }
  
  .footer-social-link.github {
      height: 34px;
      left: 172px;
      top: 7px;
      width: 34px;
      color: #4267B2;
  }
  
  .footer-copyright {
      background-color: #000000;
      color: #fff;
      padding: 15px 30px;
    text-align: center;
  }
  
  .footer-copyright-wrapper {
      margin-left: auto;
      margin-right: auto;
      max-width: 1200px;
  }
  
  .footer-copyright-text {
    color: #fff;
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      margin-bottom: 0;
      margin-top: 0;
  }
  
  .footer-copyright-link {
      color: #fff;
      text-decoration: none;
  }
  
  
#footer-twitter{
    color: #00acee;
    margin-left: 3px;
  }
  #footer-facebook{
    color: #4267B2;
    font-size: 1.8rem;
    margin-left: 3px;
  }
  #footer-insta{
    color: #E1306C;
    font-size: 1.4rem;
    margin-left: 1px;
  }
  #footer-youtube{
    color: #FF0000;
    font-size: 1.3rem;
    margin-left: 5px;
  }
  
  
  
  
  /* Media Query For different screens */

  @media (max-width:950px) {
    .footer-content{
      flex-direction: column;
    }
  }